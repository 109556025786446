import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import BeautyQuizComponent from "../../components/beauty-quiz/BeautyQuiz";

const BeautyQuizPage = () => {
  return (
    <Layout>
      <Seo title="Make Up Quiz" />
      <BeautyQuizComponent />
    </Layout>
  );
};

export default BeautyQuizPage;
